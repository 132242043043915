:root {
	--color-primary-100: #d9f9e9;
	--color-primary-200: #b2f3d3;
	--color-primary-300: #80ebb5;
	--color-primary-400: #4de298;
	--color-primary-500: #00d66c;
	--color-primary-600: #00ad57;
	--color-primary-700: #007e40;
	--color-primary-800: #00532a;

	--color-secondary-100: #f1eff9;
	--color-secondary-200: #beb2e3;
	--color-secondary-300: #9380cf;
	--color-secondary-400: #5e40b8;
	--color-secondary-500: #2800a0;
	--color-secondary-600: #200082;
	--color-secondary-700: #1a0069;
	--color-secondary-800: #17005d;

	--color-tertiary-100: #ffebf0;
	--color-tertiary-200: #ffbfd0;
	--color-tertiary-300: #ff98b3;
	--color-tertiary-400: #ff7095;
	--color-tertiary-500: #ff3268;
	--color-tertiary-600: #cf2954;
	--color-tertiary-700: #881b37;
	--color-tertiary-800: #631428;

	--color-black: #000000;
	--color-gray-300: #f9f9f9;
	--color-gray-400: #f7f7f7;
	--color-gray-500: #cccccc;
	--color-gray-600: #606060;
	--color-gray-700: #4c4c4c;

	// choice
	--choice-background-color: var(--color-secondary-500);

	--color-text-loyalty: var(--color-primary-500);

	// active color
	--color-active: var(--color-secondary-500);

	// payment banner
	--payment-description-instalments-background-color: var(--color-primary-500);
	--payment-description-background-color: var(--color-primary-100);

	// badge inclus
	--color-background-badge-inclus: var(--color-secondary-500);
	--color-text-badge-inclus: white;

	// footer
	--color-background-footer: var(--color-gray-700);
	--color-text-footer: white;

	// push notification
	--color-background-push-notification: var(--color-secondary-600);

	// timer
	--color-background-timer: var(--color-primary-500);
	--color-text-timer: white;

	// input-label
	--input-label-active-color: var(--color-secondary-500);

	//tabs
	--tab-active-color: var(--color-primary-500);
}
